@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;500;700&display=swap');

html {
    min-width: 100vw;
    min-height: 100vh;

    margin: 0;
    padding: 0;

    font-family: 'Noto Sans KR', sans-serif;

    background: rgb(6,0,105);
    background: linear-gradient(165deg, rgba(6,0,105,1) 0%, rgba(9,9,121,1) 35%, rgba(0,153,184,1) 100%);

    text-align: center;
}

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.LogoArea {
    color: rgba(255, 255, 255, 0.9);
    font-size: 200%;
    font-weight: 300;
}

.MainArea {
    background-color: white;
    border-radius: 30px;
    margin-top: 20px;
    width: 500px;
    height: 300px;
    text-align: left;

    font-weight: 500;

    font-size: 100%;
    padding: 40px 20px;
    box-sizing: border-box;
}
.MainArea .MainArea__row {
    margin-top: 10px;
}
.MainArea .MainArea__row .MainArea__col {
    vertical-align: top;
    display: inline-block;
}
.MainArea .MainArea__row .MainArea__col:nth-child(1) {
    width: 100px;
}
.MainArea .MainArea__row .MainArea__col:nth-child(2) {
    width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 25px;
}
.MainArea .MainArea__row .MainArea__col.payment_selection {
    overflow: visible;
}

.PaymentCaution {
    font-weight: 300;
    font-size: 70%;
    margin-top: 5px;
    margin-left: 10px;
}

.PaymentButtonArea {
    margin-top: 90px;
    width: 100%;
    text-align: center;
}
.PaymentButtonArea button {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 100%;
    background-color: transparent;
    border: 1px solid rgb(6,0,105);
    border-radius: 8px;
    padding: 5px 30px;
    font-weight: 300;
    cursor: pointer;
}

.Information {
    text-align: center;
}

.Footer {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 30px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    font-size: 80%;
    font-weight: 300;
}
